import {BehaviorSubject}    from 'rxjs';
import {isEqual}            from 'lodash';
import {useBehaviorSubject} from '@sirius/ui-lib/src/subjects/useBehaviorSubject';

type ServiceList = Spec.Auth.ServiceListResponseSuccessData;

const STORAGE_KEY = 'servicesList';

const readStorage = (): ServiceList  => {
    const stored = sessionStorage.getItem(STORAGE_KEY);
    return stored !== null
        ? JSON.parse(stored) as ServiceList
        : null
    ;
};

const writeStorage = (services: ServiceList) =>
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(services))
;

export const Services$ = new BehaviorSubject<ServiceList>(readStorage());

Services$.subscribe(writeStorage);

export const setServices = (v: ServiceList = null) => {
    if (!isEqual(v, Services$.value)) {
        Services$.next(v);
    }
};

export const useServices = () => useBehaviorSubject(Services$);