import React, {useState}    from 'react';
import {BoxAlignment, HBox} from '@sirius/ui-lib/src/blocks/Layout';
import {Button}             from '@sirius/ui-lib/src/blocks/Controls/Button';
import {useWindowIsMobile}  from '@sirius/ui-lib/src/subjects/WindowIsMobile';
import {ServicesDropdown}   from '@sirius/ui-lib/src/blocks/Layout/UIHeader/ServicesDropdown';
import breakpoints          from '@sirius/ui-lib/src/styles/values/breakpoints.json'
import {cluster}            from 'CheopsRoot/clusters/current';
import {headerBlock}        from 'Cheops/containers/TeacherRoomPage/blocks';
import {RightWidgets}       from 'Cheops/components/Landings/LandingsHeader';
import NotificationsList    from 'Cheops/components/Notifications/NotificationsList';
import {useServices}        from 'Cheops/subjects/Services';
import {redirectTo}         from '../helpers';
import                           './style.less';

const maxMobileWidth = parseInt(breakpoints.max_mobile_width);

const getHasServices = (services: Spec.Auth.ServiceListResponseSuccessData) => !!services && (!!services?.groups.length || !!services?.other?.length);

export const TextBookHeader = () => {
    const services = useServices();
    const isMobile = useWindowIsMobile();

    const [menuIsOpen, setMenuIsOpen]               = useState<boolean>(false);
    const [showNotifications, setShowNotifications] = useState<boolean>(false);
    const [isServicesOpen, setIsServicesOpen]       = useState<boolean>(false);

    const openMenu  = (e: React.MouseEvent) => {
        e.stopPropagation();
        setMenuIsOpen(true);
    }

    const closeMenu = () => {
        setMenuIsOpen(false);
    }

    const toggleServices = () => {
        setIsServicesOpen(false);
    }

    const hasServices = getHasServices(services);

    return (
        <HBox className = {headerBlock + headerBlock.mod('cluster', cluster.key)}
              align     = {BoxAlignment.between}
        >
            {
                hasServices
                    ? <Button className = {headerBlock.el('services-button')}
                              icon      = {!isMobile ? `expand_${isServicesOpen ? 'less' : 'more'}` : ''}
                              iconPlace = {'end'}
                              onAction  = {() => setIsServicesOpen(true)}
                              mode      = {'text'}
                    >
                        <div className={headerBlock.el('logo')}
                             onClick={() => redirectTo()}
                        />
                    </Button>
                    : <div className={headerBlock.el('logo')}
                           onClick={() => redirectTo()}
                    />
            }
            <div>
                <RightWidgets showNotifications = {() => setShowNotifications(true)}
                              user_is_anon      = {false}
                              menu_is_open      = {menuIsOpen}
                              openMenu          = {openMenu}
                              closeMenu         = {closeMenu}
                />
            </div>
            {
                showNotifications &&
                <NotificationsList hideNotifications = {() => setShowNotifications(false)} />
            }
            {
                hasServices &&
                <ServicesDropdown {...services}
                                  onClose     = {toggleServices}
                                  isTablet    = {isMobile}
                                  tabletWidth = {maxMobileWidth}
                                  isOpened    = {isServicesOpen}
                />
            }
        </HBox>
    )

};
